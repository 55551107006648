import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { IEditLog } from './types'
import { IMentorshipApplicationLabel } from 'types'
import _ from 'lodash'
import axios from 'axios'

interface EditLabelsModalContextProps {
  editLog: IEditLog[]
  editLogSummary: { renamed: number[]; removed: number[] }
  labels: IMentorshipApplicationLabel[]
  searchQuery: string
  setSearchQuery: (query: string) => void
  renameLabel: (label: IMentorshipApplicationLabel, newName: string) => void
  removeLabel: (label: IMentorshipApplicationLabel) => void
  handleUndoAll: () => void
  handleSubmitLabelChanges: () => void
  isConfirmationModalOpen: boolean
  setConfirmationModalOpen: (isOpen: boolean) => void
  isModalOpen?: boolean
  onModalClose?: () => void
  handleApplyChanges: () => void
  handleBackToEditing: () => void
}

interface IEditLabelsModalProviderProps {
  defaultLabels?: IMentorshipApplicationLabel[]
  updateLabelsLink: string
  isModalOpen?: boolean
  setModalOpen: (isOpen: boolean) => void
  children: JSX.Element
}

export const EditLabelsModalContext = createContext<
  EditLabelsModalContextProps | undefined
>(undefined)

export function EditLabelsModalProvider({
  defaultLabels,
  updateLabelsLink,
  isModalOpen = false,
  setModalOpen,
  children,
}: IEditLabelsModalProviderProps): JSX.Element {
  const [editLog, setEditLog] = useState<IEditLog[]>([])
  const [labels, setLabels] =
    useState<IMentorshipApplicationLabel[]>(defaultLabels)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [searchResultLabels, setSearchResultLabels] =
    useState<IMentorshipApplicationLabel[]>(defaultLabels)
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)

  useEffect(() => {
    setLabels(defaultLabels)
    setSearchResultLabels(defaultLabels)
  }, [defaultLabels])

  /**
   * Sort labels by name once when the component mounts
   */
  useEffect(() => {
    setLabels([...labels].sort((a, b) => a.name.localeCompare(b.name)))
  }, [])

  /**
   * Debounce the update of the edit log so that it is not updated on every key press
   */
  const updateRenameEditLog = _.debounce(
    (label: IMentorshipApplicationLabel) => {
      setEditLog([...editLog, { label, action: 'edit' }])
    },
    500,
  )

  /**
   * Rename the given label with the new name.
   * Update the labels state and add edit entry to the edit log.
   * @param label the label to be renamed
   * @param newName the new name of the label
   */
  const renameLabel = (label: IMentorshipApplicationLabel, newName: string) => {
    const newLabel = { ...label, name: newName }
    const newLabels = labels.map((l) => (l.id === label.id ? newLabel : l))
    setLabels(newLabels)
    updateRenameEditLog(label)
    updateSearchResults(searchQuery, newLabels) // immediately update search results, so that there is no delay in showing the label even in search
  }

  /**
   * Remove the given label from the labels state and add remove entry to the edit log.
   * @param label
   */
  const removeLabel = (label: IMentorshipApplicationLabel) => {
    const newLabels = labels.filter((l) => l.id !== label.id)
    setLabels(newLabels)
    setEditLog([...editLog, { label, action: 'remove' }])
  }

  /**
   * Update search results based on the query
   * @param query
   * @param _labels
   */
  const updateSearchResults = (
    query: string,
    _labels: IMentorshipApplicationLabel[],
  ) => {
    const normalizedQuery = query.trim().toLowerCase()
    setSearchResultLabels(
      _labels.filter((label) =>
        label.name.toLowerCase().includes(normalizedQuery),
      ),
    )
  }

  /**
   * Debounce the search so that it is not called on every key press
   */
  const debouncedSearch = useCallback(
    _.debounce((query) => {
      // Only called when searchQuery changes
      if (query === searchQuery) {
        return
      }
      updateSearchResults(query, labels)
    }, 300),
    [labels],
  )

  /**
   * Update search results when search query changes
   * Reset search results when search query is empty
   */
  useEffect(() => {
    if (searchQuery) {
      debouncedSearch(searchQuery)
    } else {
      setSearchResultLabels(labels) // reset search results
    }
  }, [searchQuery, debouncedSearch])

  /**
   * Reset the labels, search query, search results and edit log to their initial state
   */
  const handleUndoAll = () => {
    setLabels(defaultLabels)
    setSearchQuery('')
    setSearchResultLabels(defaultLabels)
    setEditLog([])
  }

  /**
   * Get a summary of the edit log
   * if I have renamed a label and then removed it, it should not be in the renamed list
   * i.e.
   *
   * editLog = [
   * { label: { id: 1, name: 'label1' }, action: 'edit' },
   * { label: { id: 2, name: 'label2' }, action: 'remove' },
   * { label: { id: 1, name: 'label1' }, action: 'remove' },
   * { label: { id: 3, name: 'label3' }, action: 'edit' },
   * ]
   *
   * editLogSummary = {
   * renamed: [3],
   * removed: [2, 1]
   * }
   */
  const editLogSummary = useMemo(() => {
    const summary = editLog.reduce(
      (acc, { label, action }) => {
        if (!acc.renamed) {
          acc.renamed = []
        }
        if (!acc.removed) {
          acc.removed = []
        }
        if (action === 'edit' && !acc.renamed.includes(label.id)) {
          acc.renamed.push(label.id)
        } else if (action === 'remove' && !acc.removed.includes(label.id)) {
          acc.removed.push(label.id)
        }
        return acc
      },
      { renamed: [], removed: [] },
    )

    // remove labels from the renamed list that were removed and renamed
    summary.renamed = summary.renamed.filter(
      (id) => !summary.removed.includes(id),
    )

    return summary
  }, [editLog])

  /**
   * Close the modal and reset the state
   */
  const onModalClose = useCallback(() => {
    handleUndoAll()
    setConfirmationModalOpen(false)
    setModalOpen(false)
  }, [handleUndoAll, setConfirmationModalOpen, setModalOpen])

  /**
   * Submit the changes to the labels
   * Send a post request to the server with the renamed and removed labels
   * Reload the page on success
   */
  const handleSubmitLabelChanges = useCallback(() => {
    const renamed = editLogSummary.renamed.map((id) => ({
      id,
      name: labels.find((label) => label.id === id)?.name,
    }))

    const removed = editLogSummary.removed.map((id) => ({
      id,
    }))

    axios
      .post(updateLabelsLink, {
        renamed,
        removed,
        authenticity_token: window.authenticity_token,
      })
      .then(() => {
        onModalClose()
        window.location.reload()
        window.flash('Successfully applied changes to the labels', 'success')
      })
      .catch(() => {
        window.flash('Something went wrong while updating the labels', 'alert')
      })
  }, [editLogSummary, labels])

  const handleApplyChanges = useCallback(() => {
    setConfirmationModalOpen(true)
    setModalOpen(false)
  }, [setConfirmationModalOpen, setModalOpen])

  const handleBackToEditing = useCallback(() => {
    setConfirmationModalOpen(false)
    setModalOpen(true)
  }, [setConfirmationModalOpen, setModalOpen])

  return (
    <EditLabelsModalContext.Provider
      value={{
        renameLabel,
        removeLabel,
        setSearchQuery,
        handleUndoAll,
        handleSubmitLabelChanges,
        searchQuery,
        editLog,
        editLogSummary,
        labels: searchResultLabels,
        isConfirmationModalOpen,
        setConfirmationModalOpen,
        isModalOpen,
        onModalClose,
        handleApplyChanges,
        handleBackToEditing,
      }}>
      {children}
    </EditLabelsModalContext.Provider>
  )
}
