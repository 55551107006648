import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'

import { colors, sizes, spacings } from 'stylesheets/theme'
import Button, { ButtonComponentType, Variant } from 'components/Button'

import EyeIcon from 'images/icons/eye.svg'
import EligiibleApplicantsWidget from 'components/Widget/EligibleApplicantsWidget'
import RemovedApplicantWidget from 'components/Widget/RemovedApplicantsWidget'
import PrioritizedApplicantsWidget from 'components/Widget/PrioritizedApplicantsWidget'
import ApplicantsTable from './ApplicantsTable'

import { useMentorshipExchangeDetailsContext } from './MentorshipExchangeDetailsContext'
import Axios, { AxiosResponse } from 'axios'
import MentorshipExchangeHeader from './MentorshipExchangeHeader'
import MentorshipExchangeDotMenu from './MentorshipExchangeDotMenu'
import StartMatchingModal from './Modals/StartMatchingModal'
import PendingApprovalWidget from 'components/Widget/PendingApprovalWidget'
import PendingManagerApprovalTable from './PendingManagerApprovalTable'
import ApplicationLabelsDropdown from './ApplicationLabelsDropdown'

const headerCss = css({
  '.main.with-sidebar > .content &, .main.without-sidebar > .content &': {
    flexDirection: 'column',
    alignItems: 'start',
  },
})

const headerSectionCss = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const buttonsContainerCss = css({
  display: 'flex',
  flexDirection: 'row',
  gap: '1rem',
})

const iconButtonCss = css({
  paddingLeft: 20,
  paddingRight: 20,
  svg: {
    marginRight: 10,
    path: {
      stroke: 'currentColor',
    },
  },
})

const exchangeSummariesCss = css({
  display: 'flex',
  flexDirection: 'row',
  gap: spacings.widget_gap,
  '> div.widget': {
    flexGrow: 1,
    maxWidth: `calc((100% - ${spacings.widget_gap * 2}px) / 3)`,
  },
})

const tablesCss = css({
  marginTop: '1rem',
  '.GojiCustomTable thead tr th:first-of-type:not([data-selectable="true"])': {
    width: 400,
  },
  '.debug-section': {
    paddingTop: '3rem',
    '.list-heading::after': {
      content: '" (testing list)"',
    },
  },
  '.link.remove': {
    color: colors.red,
  },

  '.applicants-table': {
    marginTop: spacings.grid_gap_basis_num * 4,
    '.GojiCustomTable': {
      tbody: {
        tr: {
          minHeight: sizes.tableRowMinHeight,
        },
      },
      tfoot: {
        tr: {
          minHeight: sizes.tableRowMinHeight,
        },
      },
    },
    '&.no-pagination': {
      '.applicants-table-pagination': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
})

interface IMentorshipStartMatchinResponse {
  success: boolean
}

interface IMentorshipExchangeDetailsComponentProps {
  className?: string
}

async function onAction(
  url: string,
  params?: Record<string, any>,
): Promise<void> {
  try {
    await Axios.post(url, {
      authenticity_token: window.authenticity_token,
      ...params,
    })

    location.reload()
  } catch (e) {
    window.flash('Something went wrong', 'alert')
  }
}

export default function MentorshipExchangeDetailsComponent({
  className = '',
}: IMentorshipExchangeDetailsComponentProps): JSX.Element {
  const {
    exchange,
    tables,
    counts,
    urls,
    labels,
    displayPendingApproval,
    updateTableData,
    updateTableFilters,
  } = useMentorshipExchangeDetailsContext()
  const [disableStartMatching, setDisableStartMatching] = useState(false)
  const [matchingStarted, setMatchingStarted] = useState(
    exchange.data.matching_status !== 'not_started',
  )
  const [openStartMatchingModal, setOpenStartMatchingModal] = useState(false)

  useEffect(() => {
    if (counts.eligible < 2 || matchingStarted) {
      setDisableStartMatching(true)
    } else {
      setDisableStartMatching(false)
    }
  }, [counts])

  const startMatching = (e: React.MouseEvent<HTMLButtonElement>) => {
    if ((matchingStarted && !e['shiftKey']) || counts.eligible < 2) return
    const url = urls.start_matching
    const params = { id: exchange.data.id }
    setMatchingStarted(true)
    Axios.post(url, {
      params,
      authenticity_token: window.authenticity_token,
    })
      .catch(() => {
        setMatchingStarted(false)
        setOpenStartMatchingModal(false)
        window.flash('Could not start matching.', 'error')
      })
      .then(
        ({
          data: { success },
        }: AxiosResponse<IMentorshipStartMatchinResponse>) => {
          setOpenStartMatchingModal(false)
          if (success) {
            window.location.reload()
          } else {
            setMatchingStarted(false)
            window.flash('Could not start matching.', 'error')
          }
        },
      )
  }

  return (
    <>
      <div className="header" css={headerCss}>
        <div className="section-header" css={headerSectionCss}>
          <div className="header-left">
            <p className="section-title">Program Overview</p>
          </div>
          <div className="buttons-container" css={buttonsContainerCss}>
            <Button
              as={ButtonComponentType.LINK}
              className="submit-form-button link"
              startIcon={<EyeIcon />}
              href={urls.view_application}
              target="_blank">
              View application form
            </Button>
            <Button
              as={ButtonComponentType.LINK}
              className="button secondary submit-form-button"
              css={iconButtonCss}
              href={urls.edit_program}>
              Edit
            </Button>
            <Button
              as={ButtonComponentType.BUTTON}
              variant={Variant.PRIMARY}
              css={iconButtonCss}
              disabled={disableStartMatching}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                if (e['shiftKey']) setMatchingStarted(false)
                setOpenStartMatchingModal(true)
              }}>
              {matchingStarted ? 'Matching...' : 'Start Matching'}
            </Button>
            <StartMatchingModal
              isOpen={openStartMatchingModal}
              onSave={startMatching}
              onRequestClose={() => setOpenStartMatchingModal(false)}
              isMatching={matchingStarted}
            />
            <MentorshipExchangeDotMenu type="pre-matching" />
          </div>
        </div>
        <MentorshipExchangeHeader exchange={exchange} />
      </div>
      <div className="mentorship-exchange-summaries" css={exchangeSummariesCss}>
        {displayPendingApproval && (
          <PendingApprovalWidget
            numPending={counts.pending_approval}
            title="Pending manager approval"
            noZero
          />
        )}
        <EligiibleApplicantsWidget
          numPeople={counts.eligible}
          title="Eligible applicants"
          noZero
        />
        <PrioritizedApplicantsWidget
          numPeople={counts.prioritized}
          title="Prioritized applicants"
          noZero
        />
        <RemovedApplicantWidget
          numPeople={counts.removed}
          title="Removed applicants"
          noZero
        />
      </div>
      <div
        className={`${className} mentorship-exchange-applicant-tables`}
        css={tablesCss}>
        {displayPendingApproval && (
          <PendingManagerApprovalTable
            pendingManagerApprovalTableProps={tables.pending_manager_approval}
            onAction={onAction}
            sendApprovalreminderUrl={urls.send_manager_approval_reminder}
            lastSentAt={exchange.data.last_manager_approval_reminder_sent_at}
          />
        )}
        <ApplicantsTable
          includeFilters
          filterOptions={tables.eligible.filterOptions}
          selectable
          showSelectedRowsCount
          tableDescription={'applicants'}
          afterUpdateTableData={(data) => {
            updateTableData('eligible', data)
          }}
          afterUpdateSelectedFilters={(filters) => {
            updateTableFilters('eligible', filters)
          }}
          {...tables.eligible}
          emptyStateChildren={
            <p css={{ fontWeight: 'normal' }}>No candidates have applied yet</p>
          }
          alwaysShowSearch={false}
          tertiaryActionNode={({ selectedRows }) => (
            <ApplicationLabelsDropdown
              defaultLabels={labels}
              createLabelsLink={urls.create_application_label}
              editLabelsLink={urls.edit_application_labels}
              assignApplicationLabelLink={urls.assign_program_application_label}
              searchLabelsLink={urls.serach_application_labels}
              updateTableData={(data) => updateTableData('eligible', data)}
              selectedRows={selectedRows}
              table="eligible"
            />
          )}
        />
        <ApplicantsTable
          afterUpdateTableData={(data) => {
            updateTableData('removed', data)
          }}
          afterUpdateSelectedFilters={(filters) => {
            updateTableFilters('removed', filters)
          }}
          {...tables.removed}
          emptyStateChildren={
            <p css={{ fontWeight: 'normal' }}>
              No applicants have been removed yet
            </p>
          }
        />
      </div>
    </>
  )
}
