import { useCallback, useEffect, useMemo, useState } from 'react'
import Axios from 'axios'
import _ from 'lodash'

import { IMainTableFilters, ITableData, ITableMeta } from 'components/Table'
import useOrderState, { TOrderDirection } from 'state/common/Order'
import { useEffectNotOnMount } from 'javascripts/general'
import usePageState from 'state/common/Page'

// export type TUserCounters = {
//   total: number
//   new: number
// }

interface IUsersStateProps {
  url: string
  initialData: ITableData
  initialMeta: ITableMeta
  initialFilters: IMainTableFilters
  initialCounters: { [counterKey: string]: number }
}

export interface TUserFilters {
  show?: string
  newSubscribers?: boolean
  text: string | undefined
  city: string[] | undefined
  title: string[] | undefined
  department: string[] | undefined
  start_date?: string
  end_date?: string
}

export interface IUserTableFilters extends IMainTableFilters {
  'filter[city]': string[]
  'filter[title]': string[]
  'filter[department]': string[]
  'filter[new_subscribers]'?: number
  page: number
}

interface IUsersState {
  loading: boolean
  data: ITableData
  meta: ITableMeta
  counters: { [counterKey: string]: number }
  filters: TUserFilters
  tableFilters: IUserTableFilters
  exportCsvUrl: string
  loadPage: (page: number) => void
  reload: () => void
  updateFilters: (newFilters: Partial<TUserFilters>) => void
  updateOrder: (column: string, direction: TOrderDirection) => void
}

const useUsersState = ({
  url,
  initialData,
  initialMeta,
  initialFilters,
  initialCounters,
}: IUsersStateProps): IUsersState => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(initialData)
  const [counters, setCounters] = useState(initialCounters)
  const [filters, setFilters] = useState<TUserFilters>({
    show: null,
    newSubscribers: false,
    text: undefined,
    city: undefined,
    title: undefined,
    department: undefined,
    ...initialFilters,
  })

  /**
   * This forces the data to be reloaded when the initialData changes
   */
  useEffect(() => {
    if (!!initialData) {
      setData(initialData)
    }
  }, [initialData])

  const { order, updateOrder } = useOrderState()
  const { page, loadPage } = usePageState()

  const tableFilters: IUserTableFilters = useMemo(
    () => ({
      'filter[show]': filters.show ? filters.show : undefined,
      'filter[new_subscribers]': filters.newSubscribers ? 1 : undefined,
      'filter[*]': filters.text || undefined,
      'filter[city]': filters.city || undefined,
      'filter[title]': filters.title || undefined,
      'filter[department]': filters.department || undefined,
      sort_col: order.column || undefined,
      sort_dir: order.direction || undefined,
      page: page + 1,
    }),
    [page, filters, order],
  )

  const loadData = useCallback(
    async (userFilters: IUserTableFilters) => {
      try {
        setLoading(true)
        const response = await Axios.get(url, {
          params: { ...filters, ...userFilters },
        })
        setLoading(false)

        setData(response.data.data)
        if (response.data.counters) {
          setCounters(response.data.counters)
        }
      } catch (error) {
        window.flash('Something went wrong!', 'alert')
      }
    },
    [setLoading, setData, setCounters],
  )

  const loadDataDebounced = useMemo(
    () =>
      _.debounce((tableFilters: IUserTableFilters) => {
        loadData(tableFilters)
      }, 200),
    [loadData],
  )

  useEffectNotOnMount(() => {
    loadDataDebounced(tableFilters)
  }, [loadDataDebounced, tableFilters])

  const reload = useCallback(() => {
    loadData(tableFilters)
  }, [loadData, tableFilters])

  const updateFilters = useCallback(
    (newFilters: Partial<TUserFilters>) => {
      loadPage(0)
      setFilters({
        ...filters,
        ...newFilters,
      })
    },
    [filters, setFilters],
  )

  const exportCsvUrl = useMemo(() => {
    const filterQueryString = _.map(tableFilters, (filterValue, filterKey) => {
      if (filterValue === undefined) {
        return undefined
      }
      if (_.isArray(filterValue)) {
        return filterValue
          .map((v) => `${filterKey}[]=${encodeURIComponent(v)}`)
          .join('&')
      }
      return `${filterKey}=${encodeURIComponent(filterValue)}`
    })
      .filter((queryString) => !!queryString)
      .join('&')
    return `${initialMeta.url.replace('.json', '.csv')}?${filterQueryString}`
  }, [tableFilters, initialMeta])

  return {
    loading,
    data,
    meta: initialMeta,
    counters,
    filters,
    tableFilters,
    exportCsvUrl,
    loadPage,
    reload,
    updateFilters,
    updateOrder,
  }
}

export default useUsersState
