import { ReactNode, forwardRef } from 'react'
import { css } from '@emotion/react'
import { spacings } from 'stylesheets/theme'

interface ContainerProps {
  children: ReactNode
  selector?: string
  alignment?: 'start' | 'center' | 'end'
  justify?: 'start' | 'center' | 'end' | 'space-between'
  direction?: 'row' | 'column'
  className?: string
  onClick?: () => void
}

const ContainerStyle = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: spacings.grid_gap_basis,
})

const Container = forwardRef<HTMLDivElement, ContainerProps>(function Container(
  {
    children,
    selector,
    alignment,
    justify,
    direction,
    className = '',
    onClick = () => {},
  }: ContainerProps,
  ref,
): JSX.Element {
  const style = selector
    ? css({
        [selector]: {
          alignItems: alignment,
          justifyContent: justify,
          flexDirection: direction,
        },
      })
    : css({
        alignItems: alignment,
        justifyContent: justify,
        flexDirection: direction,
      })
  return (
    <div
      ref={ref}
      css={[ContainerStyle, style]}
      className={className}
      onClick={onClick}>
      {children}
    </div>
  )
})

export default Container
