import Container from 'components/Container'
import Input from 'components/Input'
import Modal, { Size } from 'components/Modal'
import Switch from 'components/Switch'
import { Paragraph } from 'components/Typography'
import { useContext, useEffect, useMemo, useState } from 'react'
import EditLabelItem from './EditLabelItem'
import { EditLabelsModalContext } from './EditLabelsModalContext'
import { css } from '@emotion/react'
import { spacings } from 'stylesheets/theme'
import Button, { Variant } from 'components/Button'

const serachBarAndToggleContainerStyle = {
  width: '100%',
  justifyContent: 'space-between',
}

const labelInputsContainerStyle = css({
  width: '100%',
  minHeight: 200,
  maxHeight: 350,
  overflowY: 'auto',
  gap: 0,
})

const editLabelModalContentContainerStyle = css({
  gap: spacings.grid_gap_basis_num * 3,
})

const searchLabelInputName = 'search-label-input'

export default function EditLabelsModal(): JSX.Element {
  const {
    labels,
    setSearchQuery,
    searchQuery,
    handleUndoAll,
    editLogSummary,
    handleSubmitLabelChanges,
    isConfirmationModalOpen,
    isModalOpen,
    onModalClose,
    handleApplyChanges,
    handleBackToEditing,
  } = useContext(EditLabelsModalContext)
  const [isAllLabels, setIsAllLabels] = useState(false)
  const [shownLabels, setShownLabels] = useState(labels)

  const editLogSummaryInModal = useMemo(() => {
    const { renamed, removed } = editLogSummary

    return (
      <Paragraph>
        Are you sure you want to proceed with these updates? You have{' '}
        {renamed.length > 0 && (
          <>
            renamed <b>{renamed.length}</b>{' '}
            {renamed.length > 1 ? 'labels' : 'label'}
          </>
        )}
        {renamed.length > 0 && removed.length > 0 && ' and '}
        {removed.length > 0 && (
          <>
            removed <b>{removed.length}</b>{' '}
            {removed.length > 1 ? 'labels' : 'label'}
          </>
        )}
        . These changes will be applied everywhere they are used in the program.
      </Paragraph>
    )
  }, [editLogSummary])

  useEffect(() => {
    if (isAllLabels) {
      setShownLabels(labels)
    } else {
      setShownLabels(labels.filter((label) => !label.locked))
    }
  }, [labels, isAllLabels])

  return (
    <>
      <Modal
        title="Edit label"
        size={Size.wide}
        isOpen={isModalOpen}
        onRequestClose={onModalClose}
        onSave={handleApplyChanges}
        submitButton="Apply changes"
        cancelButton="cancel"
        isSubmitDisabled={
          editLogSummary.removed.length === 0 &&
          editLogSummary.renamed.length === 0
        }
        secondaryActionButton={
          <Button variant={Variant.LINK} onClick={handleUndoAll}>
            Undo all
          </Button>
        }
        subtitle={
          <Paragraph>
            Renaming or removing these labels will update them everywhere
            they’re assigned in the program. You can revert changes by clicking
            &quot;Undo all&quot;.{' '}
            <b>Labels previously used in matching cannot be edited.</b>
          </Paragraph>
        }>
        <Container direction="column" css={editLabelModalContentContainerStyle}>
          <Container css={serachBarAndToggleContainerStyle}>
            <Input
              type="search"
              name={searchLabelInputName}
              placeholder="Search"
              wrapperClassName="search"
              onChange={(e: Record<string, string>) => {
                setSearchQuery(e[searchLabelInputName])
              }}
              value={searchQuery}></Input>
            <Switch
              controlName={`mentorship_application_labels_active`}
              value="true"
              checked={isAllLabels}
              checkedText="All labels"
              uncheckedText="Active labels"
              onChange={() => {
                setIsAllLabels(!isAllLabels)
              }}
            />
          </Container>
          <Container direction="column" css={labelInputsContainerStyle}>
            {shownLabels.map((label) => (
              <EditLabelItem key={label.id} label={label} />
            ))}
          </Container>
        </Container>
      </Modal>
      <Modal
        size={Size.wide}
        submitButton="confirm"
        cancelButton="Back to editing"
        largeTitle
        title="Confirm your changes"
        isOpen={isConfirmationModalOpen}
        onSave={handleSubmitLabelChanges}
        onRequestClose={handleBackToEditing}>
        {editLogSummaryInModal}
      </Modal>
    </>
  )
}
