import Modal, { IModalProps, Size } from 'components/Modal'
import { modalStyle } from './modal-style'
import { Paragraph } from 'components/Typography'

export default function RemoveLabelModal({
  ...props
}: Omit<
  IModalProps,
  'size' | 'submitButton' | 'cancelButton' | 'title' | 'children'
>): JSX.Element {
  return (
    <Modal
      css={modalStyle}
      size={Size.large}
      submitButton="confirm"
      cancelButton="close"
      title="Are you sure you want to remove this label?"
      {...props}>
      <Paragraph>
        Removing this label will only delete it from this applicant. Are you
        sure you want to continue?
      </Paragraph>
    </Modal>
  )
}
