import { IMentorshipExchangeApplication } from 'types'
import { useMentorshipExchangeDetailsContext } from './MentorshipExchangeDetailsContext'
import Container from 'components/Container'
import CloseIcon from 'images/icons/close-dark.svg'
import Pill from 'components/Pill'
import RemoveLabelModal from './Modals/RemoveLabelModal'
import { useState } from 'react'
import { css } from '@emotion/react'
import { colors } from 'stylesheets/theme'

const closeIconStyle = css({
  cursor: 'pointer',
  ':hover': {
    path: {
      fill: colors.text.text_9,
    },
  },
})

const pillStyle = css({
  textTransform: 'capitalize',
  border: 'none',
})

const labelPillStyle = css({
  backgroundColor: colors.backgrounds.blue,
})

const alumniPillStyle = css({
  backgroundColor: colors.backgrounds.pink,
  color: colors.purple,
})

const transferredPillStyle = css({
  backgroundColor: colors.backgrounds.yellow,
  color: colors.states.warning,
})

interface IMentorshipApplicationLabelsCellProps {
  data: IMentorshipExchangeApplication
}

export default function MentorshipApplicationLabelsCell({
  data,
}: IMentorshipApplicationLabelsCellProps): JSX.Element {
  const [openConfirmationLabel, setOpenConfirmationLabel] = useState(false)
  const [labelRemoveUrl, setLabelRemoveUrl] = useState<string | null>(null)
  const { ajaxWithUpdates } = useMentorshipExchangeDetailsContext()

  return (
    <Container
      css={{
        maxWidth: 300,
        flexWrap: 'wrap',
      }}>
      {data.alumni && (
        <Pill
          size="large"
          css={[pillStyle, alumniPillStyle]}
          textTransform={false}>
          Alumni
        </Pill>
      )}
      {data.transferred && (
        <Pill
          size="large"
          css={[pillStyle, transferredPillStyle]}
          textTransform={false}>
          Transferred
        </Pill>
      )}
      {data.labels?.map((label) => (
        <Pill
          size="large"
          css={[pillStyle, labelPillStyle]}
          textTransform={false}
          key={label.id}>
          <Container
            css={{
              gap: 4,
            }}>
            {label.name}
            <CloseIcon
              css={closeIconStyle}
              onClick={() => {
                setOpenConfirmationLabel(true)
                setLabelRemoveUrl(label.remove_url)
              }}
            />
          </Container>
        </Pill>
      ))}
      <RemoveLabelModal
        key="remove_label_modal"
        isOpen={openConfirmationLabel}
        onRequestClose={() => {
          setOpenConfirmationLabel(false)
          setLabelRemoveUrl(null)
        }}
        onSave={() => {
          ajaxWithUpdates(labelRemoveUrl, {}, 'post')
          setOpenConfirmationLabel(false)
        }}></RemoveLabelModal>
    </Container>
  )
}
