// components
import TableComponent, {
  type TableComponentProps,
} from './components/TableComponent'

// contexts
import { TableContextProvider } from './TableContext'

// interfaces
import { EmptyStateProps } from 'components/EmptyState/EmptyState'

export interface IPaginator {
  current_page: number
  per_page: number
  total_entries: number
  total_pages: number
}

export interface ITableRow<T> {
  data: T
  link?: string
  className?: string
}

export interface ITableColumn {
  label: string
  field: string
  sort_col_name?: string
  type: string
  orderable?: boolean
  ignore_click?: boolean
  class_name?: string
  component?: string
  options?: {
    label: string
    value: string | number
  }[]
}

export interface ITableData<T = Record<string, string | number | boolean>> {
  rows: ITableRow<T>[]
  paginator: IPaginator
  [key: string]: any
}

export interface ITableMeta {
  columns: ITableColumn[]
  ids?: number[]
  url: string
  searchable?: boolean
  exportable?: boolean
  togglable_filter?: 'active_only' | 'survey_only'
}

export interface IOrderingParams {
  sort_col?: string
  sort_dir?: string
}

export interface IMainTableFilters extends IOrderingParams {
  'filter[*]'?: string
  hide?: string
  show?: string
}

export interface TableHelpers {
  selectedRows?: any[]
  handleFilterTableRows?: (
    filters: {
      hide?: string
      show?: string
    },
    sortCol?: string,
  ) => void
}

export interface ITableProps<T = Record<string, string | number | boolean>> {
  tableData: ITableData<T>
  tableMeta: ITableMeta
  title?: string
  icon?: string
  searchPlaceholder?: string
  showTotal?: boolean
  tableDescription?: string
  onSearchInputChange?: (searchParams: { 'filter[*]': string }) => void
  onOrderChange?: (orderParams: { sort_col: string; sort_dir: string }) => void
  onPageChange?: (page: number) => void
  afterUpdateTableData?: (data: ITableData<T>) => void
  afterUpdateSelectedFilters?: (dilters: IMainTableFilters) => void
  selectedFilters?: IMainTableFilters
  className?: string
  features?: Record<string, boolean>
  emptyStateImgUrl?: EmptyStateProps['imgUrl']
  object?: EmptyStateProps['object']
  emptyStateTitle?: EmptyStateProps['title']
  emptyStateSubtitle?: EmptyStateProps['subtitle']
  emptyStateActionNode?: EmptyStateProps['actionNode']
  emptyStateChildren?: EmptyStateProps['children']
  // Secondary Action nodes will be rendered on the right side of the title, to the left of search input
  secondaryActionNode?: ((helpers: TableHelpers) => JSX.Element) | JSX.Element
  // Tertiary Action nodes will be rendered on the second line of the header on the right, below seach bar, and title.
  tertiaryActionNode?: ((helpers: TableHelpers) => JSX.Element) | JSX.Element
  infoNode?: JSX.Element
  children?: TableComponentProps<T>['children']
  hiddenColumns?: string[]
  params?: any
  selectable?: boolean
  showSelectedRowsCount?: boolean
  alwaysShowSearch?: boolean
}

const TableIndex = <T,>({
  children,
  ...props
}: ITableProps<T>): JSX.Element => {
  return (
    <TableContextProvider {...props}>
      <TableComponent>{children}</TableComponent>
    </TableContextProvider>
  )
}

export {
  default as DateTableCell,
  DateTableCellVariant,
} from './components/DateTableCell'
export { default as TextTableCell } from './components/TextTableCell'
export { default as TableCellWrapper } from './components/TableCellWrapper'
export { default as MoreMenuTableCell } from './components/MoreMenuTableCell'

export default TableIndex
