import { IMentorshipApplicationLabel } from 'types'
import { EditLabelsModalProvider } from './EditLabelsModalContext'
import EditLabelsModal from './EditlLabelsModal'

interface IEditLabelsModalComponentProps {
  labels?: IMentorshipApplicationLabel[]
  updateLabelsLink: string
  isModalOpen?: boolean
  setModalOpen: (isOpen: boolean) => void
}

export default function EditLabelsModalComponent({
  labels,
  updateLabelsLink,
  isModalOpen,
  setModalOpen,
}: IEditLabelsModalComponentProps): JSX.Element {
  return (
    <EditLabelsModalProvider
      defaultLabels={labels}
      updateLabelsLink={updateLabelsLink}
      isModalOpen={isModalOpen}
      setModalOpen={setModalOpen}>
      <EditLabelsModal />
    </EditLabelsModalProvider>
  )
}
