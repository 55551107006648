import React, { useMemo } from 'react'
import { useTableContext } from '../TableContext'
import CheckboxCell from './CheckboxCell'
import { ITableColumn } from '..'

interface IColumnHeaderCellProps {
  column: ITableColumn
  checked?: boolean
  checkAllRows: () => void
  uncheckAllRows: () => void
  handleOrderChange: (field: string) => void
  sort_col?: string
  sort_dir?: string
}

function ColumnHeaderCell({
  column,
  checked = false,
  checkAllRows,
  uncheckAllRows,
  handleOrderChange,
  sort_col,
  sort_dir,
}: IColumnHeaderCellProps): JSX.Element {
  if (column.label == 'check-box') {
    return (
      <CheckboxCell
        data={{ checked }}
        onCheck={checkAllRows}
        onUncheck={uncheckAllRows}
      />
    )
  }

  return (
    <>
      {(column.orderable && (
        <button
          onClick={() =>
            handleOrderChange(column.sort_col_name || column.field)
          }>
          {column.label}
          {(column.sort_col_name || column.field) === sort_col &&
            sort_dir === 'asc' && <span>↑</span>}
          {(column.sort_col_name || column.field) === sort_col &&
            sort_dir === 'desc' && <span>↓</span>}
        </button>
      )) ||
        column.label}
    </>
  )
}

const TableHead: React.FC = () => {
  const {
    tableMeta: { columns },
    tableData: { rows },
    orderingParams: { sort_col, sort_dir },
    handleOrderChange,
    checkAllRows,
    uncheckAllRows,
    selectedRows,
  } = useTableContext()

  const selectedIds = selectedRows
    .map((row: any) => (row?.id ? row.id : 0))
    .filter((id: number) => !!id)
  const pageIds = rows
    .map((row: any) => (row?.data && row.data?.id ? row.data.id : 0))
    .filter((id: number) => !!id)

  // Are all rows on this page checked?
  const allRowsChecked = useMemo(() => {
    const selectedCount = selectedIds.filter((id: number) =>
      pageIds.includes(id),
    ).length
    return (
      !!selectedIds && selectedCount == pageIds.length && selectedCount != 0
    )
  }, [selectedRows, rows])

  return (
    <thead>
      <tr>
        {columns.map((column) => {
          const classNames = [`column-type-${column.type}`]
          if (column.class_name) {
            classNames.push(column.class_name)
          }

          return (
            <th
              key={column.field || column.component}
              data-selectable={column.label == 'check-box'}
              className={classNames.join(' ')}>
              <ColumnHeaderCell
                column={column}
                checked={allRowsChecked}
                checkAllRows={checkAllRows}
                uncheckAllRows={uncheckAllRows}
                handleOrderChange={handleOrderChange}
                sort_col={sort_col}
                sort_dir={sort_dir}
              />
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

export default TableHead
